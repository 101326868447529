import '../App.css'

import About from '../Pages/About';
import Team from '../Pages/Team';
import Home from '../Pages/Home';
import Games from '../Pages/Games';
import Contact from '../Pages/Contact';

function Body(props)
{
    let {activeTab} = props;
    activeTab = activeTab.toLowerCase()

    // HTML
    return(
        <div className='bg-transparent flex flex-col mx-8 items-center text-center sm:mx-8 md:mx-24 lg:mx-32 xl:mx-40 animate-fade'>
            {/*Show Only Clicked Tab*/}
            {activeTab === 'team' && <Team/>}
            {activeTab === 'about' && <About/>}
            {activeTab === 'home' && <Home/>}
            {activeTab === 'games' && <Games/>}
            {activeTab === 'contact' && <Contact/>}
        </div>
    )
}

export default Body;