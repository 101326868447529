import '../App.css'
import '../Animations.css'

function Home()
{
    // HTML
    return(
        <div>
            <div className='page text-center justify-center align-middle'>
                <h2>Welcome To ScreenCrow Studios</h2>
                <br/>
                <p>Use the navigation bar to explore the website!</p>
            </div>
            
            <br/>
            <hr/>
            <br/>

            <div className='flex flex-col justify-right items-center'>
                <p>Join Our Discord Community!</p>

                <iframe src="https://discord.com/widget?id=1260920846126415882&theme=dark"
                       width="350" height="500" allowtransparency="true" frameborder="0" title='Discord'
                       sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"/>
            </div>
        </div>
    )
}

export default Home;