import '../App.css'

import {BsInstagram, BsTwitterX, BsTiktok, BsYoutube, BsGithub, BsDiscord} from 'react-icons/bs';

function Footer() 
{
    // HTML
    return (
        <footer className='bg-[#1e1e1e] center align-middle h-32 inset-x-0 border-black md:px-72'>

            <div className='text-[#6b6b6b] font-bold items-center h-full flex flex-col justify-center'>
                <p>
                    ©{new Date().getFullYear()} ScreenCrow Studios. All rights reserved.
                </p>

                <a href='https://drive.google.com/drive/u/1/folders/1Xi31wZx90GnJ_Y-2zC4qr2-O7y2Rhu9y'><p className='text-xs hover:contrast-[.25]'>Press Kit</p></a>

                <br/>

                <div className='flex justify-center flex-row gap-x-2'>
                    <a href='https://www.instagram.com/screencrowstudios/'> <BsInstagram width='32' className='hover:contrast-[.25] transition-transform duration-200 transform hover:-translate-y-2'/> </a>
                    <a href='https://twitter.com/ScreenCrowStudi'> <BsTwitterX width='32' className='hover:contrast-[.25] transition-transform duration-200 transform hover:-translate-y-2'/> </a>
                    <a href='https://www.tiktok.com/@screencrowstudios'> <BsTiktok width='32' className='hover:contrast-[.25] transition-transform duration-200 transform hover:-translate-y-2'/> </a>
                    <a href='https://www.youtube.com/channel/UCLkUQ3pHeMa3E18OwpTsi9Q'> <BsYoutube width='32' className='hover:contrast-[.25] transition-transform duration-200 transform hover:-translate-y-2'/> </a>
                    <a href='https://github.com/ScreenCrowStudios'> <BsGithub width='32' className='hover:contrast-[.25] transition-transform duration-200 transform hover:-translate-y-2'/> </a>
                    <a href='https://discord.com/invite/hXz7fVqvwv'> <BsDiscord width='32' className='hover:contrast-[.25] transition-transform duration-200 transform hover:-translate-y-2'/> </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;