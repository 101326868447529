import '../App.css'

function HeaderButton(props) 
{
    const buttonClass = 'hover:contrast-[.25] transition-transform duration-300 transform hover:-translate-y-2 bg-transparent border-white rounded-3xl text-white font-bold py-1 px-3 font-screenCrowFont hover:rounded-2xl mx-4 sm:border-0 lg:border-4';

    // HTML
    return (
        <button onClick={props.onClick} className={buttonClass}>
            {props.title && <h1 className='text-5xl'>{props.name}</h1>}
            {!props.title && props.name}
        </button>
    );
}

export default HeaderButton;
