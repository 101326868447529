import '../App.css';
import '../Animations.css';

import { useEffect, useState } from 'react';
import ScreenCrowBlack from "../Images/ScreenCrowBlack.png";

function Team() {
    const [teamMembers, setTeamMembers] = useState([]);

    useEffect(() => {
        // Fetch the data from port 3001
        fetch('https://screencrowstudios.com:3001/team')
            .then(response => response.json())
            .then(data => setTeamMembers(data))
            .catch(error => console.error('Error fetching team data:', error));
    }, []);

    // Sorting based on the display_name
    const sortedTeamMembers = teamMembers.sort((a, b) => {
        if (a.full_name < b.full_name) return -1;
        if (a.full_name > b.full_name) return 1;
        return 0;
    });

    // HTML
    return (
        <div className='page text-center'>
            <h1>Team</h1>

            <div className='flex flex-wrap justify-center gap-16'>
                {sortedTeamMembers.map((member) => (
                    <div key={member.login}>
                        <img
                            src={member.avatar_url === null ? ScreenCrowBlack : member.avatar_url}
                            alt={member.full_name || member.login}
                            className='border-black border-4 w-[256px] h-[256px] rounded-full'
                        />

                        {member.full_name ? (
                            member.full_name.split(' ').map((word, index, array) => {
                                // Remove middle names if there are three words
                                if (array.length === 3 && index === 1) return null;
                                return (
                                    <h3 key={index}>
                                        {word}
                                        {array.length === 1 && (
                                            <>
                                                <br />
                                                <br />
                                            </>
                                        )}
                                        {array.length === 2 && (
                                            <br />
                                        )}
                                    </h3>
                                );
                            })
                        ) : (
                            <h3>
                                {member.login}
                                <>
                                    <br />
                                    <br />
                                </>
                            </h3>
                        )}

                        <hr />
                        <div>
                            {member.teams.map((team, index) => (
                                <p key={index} 
                                className={ team === 'Management' ? 'text-[#ff1818]' : 
                                            team === 'Art' ? 'text-[#308c5c]' : 
                                            team === 'Development' ? 'text-[#389cfc]' :
                                            team === 'Animation' ? 'text-[#ff8434]' :
                                            team === 'Writing' ? 'text-[#60046c]' :
                                            'text-gray-400'}>
                                    {team}
                                </p>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <br />

            <p>
                Interested in joining the team? Head over to the <b><a className='hover:text-[#454545]' href='/contact'>Contact</a></b> page and email us your resume and/or portfolio!
            </p>
        </div>
    );
}

export default Team;