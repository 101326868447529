import './App.css'

import {useState} from "react";

import Body from './Elements/Body';
import Footer from './Elements/Footer';
import Header from './Elements/Header';

function App() 
{
    const [activeTab, setActiveTab] = useState('');

    //Header Buttons
    const handleButtonClick = (tabName) =>
    {
        setActiveTab(tabName);
    };

    // HTML
    return (
        <div className='min-h-screen overflow-hidden flex flex-col text-white'>
            <Header onButtonClick={handleButtonClick}/>
            <div className='bg-gradient-to-b from-black to-[#1e1e1e] flex-grow py-10'>
                <Body activeTab={activeTab}/>
            </div>
            <Footer/>
        </div>
    );
}

export default App;

