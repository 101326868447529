import '../App.css'
import '../Animations.css'

function About()
{
    // HTML
    return(
        <div className='page text-center'>
            <h1>About</h1>
            <br/>
            <div className='bg-black border-transparent border-8 rounded-3xl'>
                <img src='/ScreenCrowStudiosLogo1.png' width='480' alt='ScreenCrowStudiosLogo' className='py-10 mx-auto'/>
            </div>
            <br/>
            <p className='text-2xl'>
                <b>ScreenCrow</b> is a small, Indie Game Studio and Publisher with people from all around the
                globe. ScreenCrow Studios was founded in 2023 and it's goal is to create
                games for people to enjoy, and have fun doing it! Our small team is responsible for <b>Developing</b>,
                <b> Publishing</b> and <b>Marketing</b> our games.
            </p>
            <br/>
        </div>
    )
}

export default About;