import '../App.css'
import '../Animations.css'

function Games()
{
    // HTML
    return(
        <div className='page flex flex-col items-center'>
            <h1>Games</h1>
            <h2>Something Is Being Cooked..</h2>
            <img src='./Run.gif' width='256' className='mr-[8rem]'/>
        </div>
    )
}

export default Games;